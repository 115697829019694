import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import FormsNew from "./FormsNew";
import { Button } from "react-bootstrap";
import { t } from "../../translations/Translation";
import DeleteIcon from "../../static/icons/Delete.svg";
import AddIcon from "../../static/icons/AddPlusIcon.png";
import CalendarIcon from "../../static/icons/Calendar.svg";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetPlanCreationOptionsUrl, CreatePlanApiUrl, DeleteSinglePlan } from "../../routes/ApiEndPoints";
import { toast } from 'react-toastify';
import { GetTimeDifference } from "../../utilities/CommonFunctions";
import AddLeaveIcon from "../../static/icons/addLeave.svg";
import ErrorPopup from "../../utilities/popup/ErrorPopup";


export default function CreatePlanPopup({ setPlanPopup, employeeId, planningDate, wid, locId, planData, dropDownData, updatePlan, setDataRefresh, dataRefresh, enableShift, GetEmployeePlans, setLeavePopup, setPlanIdForLeave, workstationOptions = [] }) {

    // let fieldsData = {
    //     'start_time': "",
    //     'end_time': "",
    //     'contract_hours': "",
    //     'extra_info':"",
    //     // 'leave_status': false,
    //     // 'reason': "",
    //     // 'holiday_code_id': ""
    // }

    // const [rowArr, setRowArr] = useState(planData.length > 0 ? enableShift ? planData : [...planData, fieldsData] : [fieldsData]);
    const [rowArr, setRowArr] = useState(planData?.length > 0 ? enableShift ? planData : [...planData, 1] : [1]);
    const [selectedEmployeeType, setSelectedEmployeeType] = useState(dropDownData ? dropDownData['employee_type'] : '');
    const [selectedFunction, setSelectedFunction] = useState(dropDownData ? dropDownData['function'] : '');
    const [planningData, setPlanningData] = useState({
        'employee_id': employeeId,
        'location_id': locId,
        'workstation_id': wid,
        'function_id': dropDownData['function'] ? dropDownData['function'].value : '',
        'employee_type_id': dropDownData['employee_type'] ? dropDownData['employee_type'].value : '',
        'dates': [planningDate],
        'timings': planData
    });

    const [multipleDatesStatus, setMultipleDatesStatus] = useState(false);
    const [employeeTypeOptions, setEmployeeTypeOptions] = useState([]);
    const [errors, setErrors] = useState([]);
    const [Success, setSuccess] = useState(true);

    const [selectedWorkstation, setSelectedWorkstation] = useState('')

    const checkboxList = [
        {
            name: t("SELECT_FOR_MULTIPLE_PLANNINGS"),
            key: 'active',
            checked: multipleDatesStatus,
        },
    ]

    const setInitialWorkStation = () => {
        if (Array.isArray(workstationOptions)) {
            const selectedWorkstation = workstationOptions?.find(item => item.value === wid);
            if (selectedWorkstation) {
                setSelectedWorkstation(selectedWorkstation);
            }
        }
    }

    // useEffect(() => {
    //     let data = { ...planData }
    //     data.employee_id = employeeId
    //     data.location_id = locId
    //     data.workstation_id = wid
    //     data.function_id = dropDownData['function'] ? dropDownData['function'].value : ''
    //     data.employee_type_id = dropDownData['employee_type'] ? dropDownData['employee_type'].value : ''
    //     data.dates = [planningDate]
    //     data.timings = [...planData, fieldsData]
    //     setPlanningData(data)
    // }, [])

    useEffect(() => {
        let requestData = {
            "employee_id": employeeId,
            "date": planningDate
        }
        AXIOS.service(GetPlanCreationOptionsUrl, 'POST', requestData)
            .then((result) => {
                if (result?.success) {
                    setEmployeeTypeOptions(result.data)
                    if (result.data.employee_types?.length === 1) {
                        setSelectedEmployeeType(result.data.employee_types[0])
                        planningData['employee_type_id'] = result.data.employee_types[0]?.value
                        if (result.data?.functions[result.data.employee_types[0]?.value]?.length === 1) {
                            setSelectedFunction(result.data.functions[result.data.employee_types[0]?.value][0])
                            planningData['function_id'] = result.data.functions[result.data.employee_types[0]?.value][0]?.value
                        }
                    }
                    // setEmployeeList(result.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        setInitialWorkStation();
    }, [])


    const changeCheckbox = () => {
        setMultipleDatesStatus(!multipleDatesStatus)
    }

    const formFields = !updatePlan ? [
        { title: t("WORK_STATION"), name: 'workstation_id', required: true, options: workstationOptions !== undefined ? workstationOptions : [], selectedOptions: selectedWorkstation, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
        { title: t("EMPLOYEE_TYPE"), name: 'employee_type_id', required: true, options: employeeTypeOptions !== undefined ? employeeTypeOptions.employee_types : [], selectedOptions: selectedEmployeeType, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
        { title: t("FUNCTION_TITLE"), name: 'function_id', required: true, options: employeeTypeOptions !== undefined && employeeTypeOptions.functions !== undefined ? employeeTypeOptions.functions[selectedEmployeeType !== undefined ? selectedEmployeeType.value : selectedEmployeeType] : [], selectedOptions: selectedFunction, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
        { title: '', required: false, type: 'checkbox', checkboxList: checkboxList, changeCheckbox: changeCheckbox, style: "col-md-12 mt-4 mb-2 float-left" }
    ] : [
        { title: t("WORK_STATION"), name: 'workstation_id', required: true, options: workstationOptions !== undefined ? workstationOptions : [], selectedOptions: selectedWorkstation, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
        { title: t("EMPLOYEE_TYPE"), name: 'employee_type_id', required: true, options: employeeTypeOptions !== undefined ? employeeTypeOptions.employee_types : [], selectedOptions: selectedEmployeeType, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
        { title: t("FUNCTION_TITLE"), name: 'function_id', required: true, options: employeeTypeOptions !== undefined && employeeTypeOptions.functions !== undefined ? employeeTypeOptions.functions[selectedEmployeeType !== undefined ? selectedEmployeeType.value : selectedEmployeeType] : [], selectedOptions: selectedFunction, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
    ]

    const planFields = [
        { title: t("START_TIME"), name: "start_time", required: true, type: "time", style: "w-20 px-2 mt-1 float-left" },
        { title: t("END_TIME"), name: "end_time", required: true, type: "time", style: "w-20 px-2 mt-1 float-left" },
        { title: t("CONTRACT_HOURS"), name: 'contract_hours', required: true, type: 'text', style: "w-20 px-2 mt-1 float-left" },
        { title: t("EXTRA_INFO"), name: 'extra_info', required: false, type: 'text', style: "w-40 pl-2 pr-0 mt-1 float-left" },
    ]

    const multiDatePicker = [
        { title: "", name: "dates", required: false, type: "date", isMulti: true, style: "col-md-12 pr-0 float-left" },
    ]

    const setValues = (index, name, value, field) => {
        const planning_data = { ...planningData };

        if (name === 'dates') {
            let arr = planning_data['dates']
            if (value !== null) {
                value?.map((date, i) => {
                    let formattedData = date?.format("DD-MM-YYYY")
                    if (!arr.includes(formattedData)) {
                        arr.push(formattedData)
                    } else if (arr.includes(formattedData)) {
                        arr = arr.filter(item => item === formattedData)
                    }
                })
                planning_data['dates'] = arr
            } else {
                planning_data['dates'] = []
            }
        } else if (field !== 'dropdown') {
            if (field === 'time' || name === 'contract_hours' || name === 'extra_info') {
                planning_data['timings'][index] = planning_data['timings'][index] ? planningData['timings'][index] : {}
                planning_data['timings'][index][name] = value
                if (name === 'start_time' && planning_data['timings'][index]?.end_time) {
                    planning_data['timings'][index]['contract_hours'] = GetTimeDifference(value, planning_data['timings'][index]['end_time'])
                } else if (name === 'end_time' && planning_data['timings'][index]?.start_time) {
                    planning_data['timings'][index]['contract_hours'] = GetTimeDifference(planning_data['timings'][index]['start_time'], value)
                }
            } else {
                planning_data[name] = value
            }
        } else {
            if (name === 'employee_type_id') {
                setSelectedEmployeeType(value);
            } else if (name === 'function_id') {
                setSelectedFunction(value);
            } else {
                setSelectedWorkstation(value)
            }
            planning_data[name] = value.value
        }
        setPlanningData(planning_data);
    }

    const deletePlan = (Pid) => {
        AXIOS.service(DeleteSinglePlan + Pid, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh);
                    setPlanPopup(false);
                    // setWarningMessage('')
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const AddRemovePlanRow = (val, index, Pid) => {

        if (val === 'add') {
            // setPlanningData((prev) => ({
            //     ...prev, timings: [...prev.timings, fieldsData]
            // }))
            let arrData = [...rowArr]
            // arrData.push(fieldsData);

            arrData.push(1);
            setRowArr(arrData);
        } else {
            // setPlanningData((prev) => {
            //     const newArr = [...prev.timings]
            //     newArr.splice(index, 1)
            //     return {
            //         ...prev, timings: newArr
            //     }
            // })
            let arrData = [...rowArr]
            arrData.splice(index, 1);
            setRowArr(arrData);
            if (Pid !== undefined) {
                deletePlan(Pid.plan_id)
            }
        }

    }

    const handleLeaveForPlanPopup = (planId, planningsData, timingsIndex) => {

        if (planId) {
            setLeavePopup(true)
            setPlanPopup(false)
            setPlanIdForLeave(planId)
        } else if ((timingsIndex < planningData["timings"].length) && planningData["timings"][timingsIndex]?.contract_hours != 0 && planningData["timings"][timingsIndex]?.contract_hours !== undefined && planningData["timings"][timingsIndex]?.contract_hours !== null) {
            // SavePlan()
            setLeavePopup(true)
            setPlanPopup(false)
        } else {
            setErrors([t("ADD_PLAN_FIRST")])
        }

        // if (!data.leave_status) {
        //     // alert(data.leave_status)
        //     // setLeavePopup(true)
        //     // setPlanPopup(false)
        //     // setPlanIdForLeave(planId)
        // }
    }

    const SavePlan = () => {
        AXIOS.service(CreatePlanApiUrl, 'POST', planningData)
            .then((result) => {
                if (result?.success) {
                    setPlanPopup(false);
                    // setDataRefresh(!dataRefresh);
                    GetEmployeePlans(employeeId, wid, '', wid !== selectedWorkstation?.value)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                    // setEmployeeList(result.data)
                } else {
                    setErrors(result?.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })
    }

    return (
        <Modal
            show={true}
            onHide={() => setPlanPopup(false)}
            size="xl"
            className=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={true}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='container' >
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    {t("ADD_PLANNING")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="border">
                    <FormsNew
                        view="filters"
                        data={formFields}
                        SetValues={setValues}
                        formattedData={planningData}
                    />
                    {multipleDatesStatus && !updatePlan &&
                        <div className="col-md-12 d-flex p-0">
                            <div className="col-md-10 p-0">
                                <FormsNew
                                    view="filters"
                                    data={multiDatePicker}
                                    SetValues={setValues}
                                    formattedData={planningData}
                                />
                            </div>
                            <img src={CalendarIcon} className="pb-3 ml-4 shortcut_icon pointer" alt={t("CALENDER")}></img>
                        </div>
                    }
                    {rowArr?.map((row, index) => {
                        return (
                            <div key={row} className="col-md-12 d-flex mb-3">
                                <div className={"col-md-10 p-0 border" + (row.leave_status ? ' border-danger' : '')}>
                                    <FormsNew
                                        view="filters"
                                        planIndex={index}
                                        data={planFields}
                                        SetValues={setValues}
                                        formattedData={planningData['timings'] !== undefined ? planningData['timings'] : []}
                                    />
                                </div>
                                {((planningData['timings'] !== undefined && planningData['timings'][index] !== undefined && planningData['timings'][index]['employee_worked'] === false) || planningData['timings'] !== undefined || planningData['timings'][index] === undefined || rowArr.length - 1 === index) && <div className="p-4 ml-2 text-center border">
                                    <img className="shortcut-icon pointer" src={rowArr.length - 1 === index ? AddIcon : DeleteIcon}
                                        onClick={() => AddRemovePlanRow(rowArr.length - 1 === index ? 'add' : 'remove', index, planningData['timings'][index])} alt={rowArr.length - 1 === index ? t("ADD") : t("DELETE")}></img>
                                </div>}
                                {row.plan_id !== undefined && !row.leave_status && <div className="ml-2 p-4 text-center border">
                                    <img className="shortcut-icon pointer" src={AddLeaveIcon}
                                        onClick={() => handleLeaveForPlanPopup(row.plan_id, row.plan_id ? row : planningData, index)} alt={t("ADD_LEAVE_ICON")}></img>
                                </div>}
                            </div>
                        )
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={'button-style float-left' + (Success ? "" : " disabled")} onClick={() => { SavePlan(); setSuccess(false); }}> {/*setPlanPopup(false); */}
                    {t("SAVE")}
                </Button>
                <Button className='button-style' onClick={() => setPlanPopup(false)}>
                    {t('CLOSE')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
